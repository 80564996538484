import '../scss/style.scss';
import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import Sticky from 'sticky-js';

document.addEventListener('DOMContentLoaded', function () {

    if (window.innerWidth > 960) {
        let sticky = new Sticky('.col-fixed');
    }

    var swiper = new Swiper(".Swiperbanner", {
        modules: [Navigation, Autoplay, Pagination, EffectFade],
        slidesPerView: 1, // Asegúrate de que solo se muestre un slide a la vez
        spaceBetween: 0,
        loop: true,
        effect: "fade",
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        speed: 1000, // Ajusta la velocidad de la transición (en milisegundos)
    });

    var swiper = new Swiper(".SwiperSectionOne", {
        modules: [Navigation, Autoplay, Pagination],
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    var swiper = new Swiper(".SwiperSectionTwo", {
        modules: [Navigation, Autoplay, Pagination],
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    var swiper = new Swiper(".SwiperCompany", {
        modules: [Autoplay, Pagination, EffectFade],
        slidesPerView: 1,
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination-company-slider",
            clickable: true,
        },
    });

    var swiper = new Swiper(".SwiperSectionThree", {
        modules: [Navigation, Pagination],
        spaceBetween: 30,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
            }
        }

    });

    var swiper = new Swiper(".SwiperSectionFour", {
        modules: [Autoplay],
        spaceBetween: 40,
        grabCursor: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1280: {
                slidesPerView: 3,
            }
        }
    });

    var swiper = new Swiper(".company-text-logos-slider", {
        modules: [Autoplay, Pagination],
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".company-text-logos-slider-pagination",
            clickable: true,
        },
        breakpoints: {
            0: {
                autoHeight: true,
            },
            768: {
                autoHeight: false,
            }
        }
    });

    //animation the header

    let lastScrollTop = 0;
    const header = document.getElementById('header');

    window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {

            header.style.top = '-130px';
        } else {

            header.style.top = '0';
        }
        lastScrollTop = scrollTop;
    });

    //animation opaciity scroll banner
    if(document.querySelector("#myContainer")) {
        window.onscroll = function() {
            scrollFunction();
        };

        function scrollFunction() {
            var container = document.getElementById("myContainer");
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            var elementHeight = container.clientHeight;

            if (scrollTop > 100) {
                container.classList.remove("hidden");
            } else {
                container.classList.add("hidden");
            }

            var opacity = ((1 - (elementHeight - scrollTop) / elementHeight) * 0.9) + 0.2;
            container.style.opacity = opacity;
        }
    }

   // hover menu
    document.querySelectorAll('.menu-link').forEach(link => {
        link.addEventListener('mouseover', () => {
            document.querySelectorAll('.menu-link').forEach(otherLink => {
                if (otherLink !== link) {
                    otherLink.classList.add('hover-effect');
                }
            });
        });

        link.addEventListener('mouseout', () => {
            document.querySelectorAll('.menu-link').forEach(otherLink => {
                otherLink.classList.remove('hover-effect');
            });
        });
    });

    //animation content
    const textElements = document.querySelectorAll('.content-show');

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fade-in');
                observer.unobserve(entry.target);
            }
        });
    }, options);

    textElements.forEach(element => {
        observer.observe(element);
    });

    //animation content_two
    const textElementsTwo = document.querySelectorAll('.content-show-two');

    const optionsTwo = {
        root: null,
        rootMargin: '0px',
        threshold: 0.6
    };

    const observerTwo = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fade-in');
                observer.unobserve(entry.target);
            }
        });
    }, optionsTwo);

    textElementsTwo.forEach(element => {
        observerTwo.observe(element);
    });

    //acordean
    const items = document.querySelectorAll('.accordion-item');

    items.forEach(header => {
        header.addEventListener('click', function () {
            this.classList.toggle('active');
        });
    });

    //open menu mobile
    if (document.querySelector('.open-mobile')) {
        const openMobileLink = document.querySelector('.open-mobile');
        const closeMobileLink = document.querySelector('.close-mobile');
        const navMain = document.getElementById('nav-main');

        openMobileLink.addEventListener('click', function (e) {
            e.preventDefault()
            navMain.classList.remove('lg:hidden');
            navMain.classList.add('lg:flex');
        });

        closeMobileLink.addEventListener('click', function (e) {
            e.preventDefault()
            navMain.classList.remove('lg:flex');
            navMain.classList.add('lg:hidden');
        });
    }

    //focus input modaly modal
    const modal = document.getElementById('modal-search');
    const openModalButton = document.getElementById('openModalButton');
    const closeModalButton = document.getElementById('closeModalButton');
    const modalInput = document.querySelector('.modalInput');

    openModalButton.addEventListener('click', function () {
        modal.classList.remove('hidden');
        setTimeout(() => {
            modalInput.focus(); // Establecer foco en el input después de abrir el modal
        }, 200); // Retraso para asegurar que el modal esté completamente visible
    });

    closeModalButton.addEventListener('click', function () {
        modal.classList.add('hidden');
    });

    // Cerrar el modal al hacer clic fuera del contenido del modal
    window.addEventListener('click', function (event) {
        if (event.target == modal) {
            modal.classList.add('hidden');
        }
    });

    const searchForm = document.getElementById("search-form");
    const searchInput = searchForm.querySelector("input[type='text']");
    const searchResults = document.getElementById("search-results");

    if (!searchForm || !searchInput || !searchResults) {
        return;
    }

    const ajaxUrl = searchForm.dataset.ajaxUrl;
    let debounceTimer;

    searchInput.addEventListener("input", function () {
        clearTimeout(debounceTimer);

        const query = searchInput.value;

        searchResults.style.display = "none";

        if (query.length > 2) {
            debounceTimer = setTimeout(() => {
                fetch(ajaxUrl + "?action=search_ajax&query=" + encodeURIComponent(query))
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            throw new Error('Error en la petición AJAX');
                        }
                    })
                    .then(text => {
                        searchResults.innerHTML = text;
                        searchResults.style.display = "block";
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }, 300); // 300 ms de espera
        } else {
            searchResults.innerHTML = "";
        }
    });

    //click images texto iso
    document.querySelectorAll('.container').forEach(container => {
        const images = container.querySelectorAll('.imagen-content');
        const texts = container.parentElement.querySelectorAll('.texto-content');

        // Hacer que un texto esté siempre visible
        const defaultText = container.parentElement.querySelector('.text1');
        if (defaultText) {
            defaultText.classList.add('block');
        }

        images.forEach(image => {
            image.addEventListener('click', function() {
                const textClass = this.getAttribute('data-text');

                texts.forEach(text => {
                    text.classList.remove('block');
                    text.classList.add('hidden');
                });

                const selectedText = container.parentElement.querySelector(`.${textClass}`);
                if (selectedText) {
                    selectedText.classList.remove('hidden');
                    selectedText.classList.add('block');
                }

                images.forEach(img => img.classList.remove('selected'));
                this.classList.add('selected');
            });
        });
    });

    document.querySelectorAll('.text-container').forEach(container => {
        const defaultText = container.querySelector('.text1');
        if (defaultText) {
            defaultText.classList.add('block');
        }
    });

    //titulo formulario
    const scripts = document.querySelectorAll('script[type="text/javascript"]');

    // Si hay algún script encontrado, desactiva su contenido
    if (scripts.length > 0) {
        scripts[0].textContent = '/* ' + scripts[0].textContent + ' */';
    }

    // Copy to clipboard
    if(document.querySelector('.copy-to-clipboard')) {
        const ctc = document.querySelector('.copy-to-clipboard');

        ctc.addEventListener('click', (e) => {
            e.preventDefault();

            const currentUrl = window.location.href;
            navigator.clipboard.writeText(currentUrl).then(() => {
                const text = document.querySelector('.ctc-text');
                text.classList.add('opacity-100');

                setTimeout(() => {
                    text.classList.remove('opacity-100');
                }, 3000);
            }).catch((error) => {
                console.error('Error al copiar URL al portapapeles: ', error);
            });
        })
    }

    // Submenús en Mobile
    if (window.innerWidth < 1100) {
        var openSubElements = document.querySelectorAll(".open-sub");

        openSubElements.forEach(function (openSubElement) {
            openSubElement.addEventListener("click", function () {
                var parentLi = openSubElement.closest("li");
                var navigationLi = parentLi.querySelector(".navigation-li");

                var allNavigationLi = document.querySelectorAll(".navigation-li");
                allNavigationLi.forEach(function (item) {
                    if (item !== navigationLi) {
                        item.classList.remove("lg:block", "lg:visible", "slideDown");
                        item.classList.add("lg:hidden", "slideUp");
                    }
                });

                navigationLi.classList.toggle("lg:block");
                navigationLi.classList.toggle("lg:hidden");

                if (navigationLi.classList.contains("lg:block")) {
                    navigationLi.classList.add("slideDown");
                    navigationLi.classList.remove("slideUp");
                    openSubElements.forEach(function (item) {
                        item.classList.remove("active");
                    });
                    openSubElement.classList.add("active");
                } else {
                    navigationLi.classList.add("slideUp");
                    navigationLi.classList.remove("slideDown");
                    openSubElement.classList.remove("active");
                }
            });
        });
    }

    // Llenar select de Departamento y Ciudad en Línea de Transparencia
    // if(document.querySelector('.transparency-line-form')) {
    //     let data = {
    //         "Amazonas": ["Leticia", "Puerto Nariño"],
    //         "Antioquia": ["Medellín", "Bello", "Itagüí", "Envigado", "Apartadó", "Turbo"],
    //         "Arauca": ["Arauca", "Arauquita", "Saravena", "Tame"],
    //         "Atlántico": ["Barranquilla", "Soledad", "Malambo", "Puerto Colombia"],
    //         "Bogotá": ["Bogotá D.C."],
    //         "Bolívar": ["Cartagena", "Magangué", "Turbaco", "El Carmen de Bolívar"],
    //         "Boyacá": ["Tunja", "Duitama", "Sogamoso", "Chiquinquirá"],
    //         "Caldas": ["Manizales", "Villamaría", "Chinchiná", "La Dorada"],
    //         "Caquetá": ["Florencia", "San Vicente del Caguán", "Puerto Rico"],
    //         "Casanare": ["Yopal", "Aguazul", "Villanueva"],
    //         "Cauca": ["Popayán", "Santander de Quilichao", "Puerto Tejada"],
    //         "Cesar": ["Valledupar", "Aguachica", "Agustín Codazzi"],
    //         "Chocó": ["Quibdó", "Istmina", "Riosucio"],
    //         "Córdoba": ["Montería", "Cereté", "Lorica"],
    //         "Cundinamarca": ["Bogotá D.C.", "Soacha", "Girardot", "Facatativá"],
    //         "Guainía": ["Inírida"],
    //         "Guaviare": ["San José del Guaviare"],
    //         "Huila": ["Neiva", "Pitalito", "Garzón"],
    //         "La Guajira": ["Riohacha", "Maicao", "Uribia"],
    //         "Magdalena": ["Santa Marta", "Ciénaga", "Fundación"],
    //         "Meta": ["Villavicencio", "Acacías", "Granada"],
    //         "Nariño": ["Pasto", "Tumaco", "Ipiales"],
    //         "Norte de Santander": ["Cúcuta", "Ocaña", "Pamplona"],
    //         "Putumayo": ["Mocoa", "Puerto Asís", "Orito"],
    //         "Quindío": ["Armenia", "Calarcá"],
    //         "Risaralda": ["Pereira", "Dosquebradas", "Santa Rosa de Cabal"],
    //         "San Andrés y Providencia": ["San Andrés"],
    //         "Santander": ["Bucaramanga", "Barrancabermeja", "Floridablanca"],
    //         "Sucre": ["Sincelejo", "Corozal"],
    //         "Tolima": ["Ibagué", "Espinal"],
    //         "Valle del Cauca": ["Cali", "Palmira", "Buenaventura"],
    //         "Vaupés": ["Mitú"],
    //         "Vichada": ["Puerto Carreño"]
    //     };

    //     let departamentoSelect = document.querySelector(".departments-select select");
    //     let ciudadSelect = document.querySelector(".cities-select select");

    //     Object.keys(data).forEach(function(estado) {
    //         let option = document.createElement("option");
    //         option.value = estado;
    //         option.text = estado;
    //         departamentoSelect.add(option);
    //     });

    //     departamentoSelect.addEventListener('change', () => {
    //         ciudadSelect.innerHTML = '<option value="" selected>Seleccionar Ciudad</option>';

    //         var departamentoSeleccionado = departamentoSelect.value;

    //         if (departamentoSeleccionado in data) {
    //             data[departamentoSeleccionado].forEach(function(ciudad) {
    //                 var option = document.createElement("option");
    //                 option.value = ciudad;
    //                 option.text = ciudad;
    //                 ciudadSelect.add(option);
    //             });
    //         }
    //     });
    // }
})